import { FC } from 'react';

const IconPlus: FC<{
	width?: number;
	height?: number;
	color?: string;
}> = ({ width = 29, height = 29, color = '#555A6B' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 29 29'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M22.994 13.2131H15.7869V6.00595C15.7869 5.68738 15.6604 5.38185 15.4351 5.15658C15.2098 4.93132 14.9043 4.80476 14.5857 4.80476C14.2671 4.80476 13.9616 4.93132 13.7363 5.15658C13.5111 5.38185 13.3845 5.68738 13.3845 6.00595V13.2131H6.17739C5.85881 13.2131 5.55328 13.3396 5.32802 13.5649C5.10275 13.7902 4.9762 14.0957 4.9762 14.4143C4.9762 14.7329 5.10275 15.0384 5.32802 15.2637C5.55328 15.4889 5.85881 15.6155 6.17739 15.6155H13.3845V22.8226C13.3845 23.1412 13.5111 23.4467 13.7363 23.672C13.9616 23.8973 14.2671 24.0238 14.5857 24.0238C14.9043 24.0238 15.2098 23.8973 15.4351 23.672C15.6604 23.4467 15.7869 23.1412 15.7869 22.8226V15.6155H22.994C23.3126 15.6155 23.6182 15.4889 23.8434 15.2637C24.0687 15.0384 24.1952 14.7329 24.1952 14.4143C24.1952 14.0957 24.0687 13.7902 23.8434 13.5649C23.6182 13.3396 23.3126 13.2131 22.994 13.2131Z'
				fill={color}
			/>
		</svg>
	);
};

export default IconPlus;
