import { FC, ReactNode } from 'react';
import Nav from '../nav/nav';
import Seo from '../seo/seo';

const Layout: FC<{ children: ReactNode; title: string }> = ({
	children,
	title,
}) => {
	return (
		<div>
			<Seo {...{ title }} />
			<Nav />
			<div className='hidden lg:block min-h-[calc(100vh_-_97px)] text-primary-gray mt-[97px]'>
				{children}
			</div>
		</div>
	);
};

export default Layout;
