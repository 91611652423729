import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import FifthAppIcon from '../../assets/icons/fifthAppIcon';
import FirstAppIcon from '../../assets/icons/firstAppIcon';
import FourthAppIcon from '../../assets/icons/fourthAppIcon';
import SecondAppIcon from '../../assets/icons/secondAppIcon';
import ThirdAppIcon from '../../assets/icons/thirdAppIcon';
import { sideBarData } from '../../utils/data/staticData';

const SideBar: FC = () => {
	const { app } = useParams();

	const iconSwitch = (icon: string) => {
		switch (icon) {
			case 'vla_first':
				return (
					<FirstAppIcon
						color={app && app === 'vla-qa' ? '#555A6B' : '#D3D6DF'}
					/>
				);
			case 'vla_second':
				return (
					<SecondAppIcon
						color={
							app && app === 'vla-bulk' ? '#555A6B' : '#D3D6DF'
						}
					/>
				);
			case 'third':
				return (
					<ThirdAppIcon
						color={
							app && app === 'third-app' ? '#555A6B' : '#D3D6DF'
						}
					/>
				);
			case 'fourth':
				return (
					<FourthAppIcon
						color={
							app && app === 'fourth-app' ? '#555A6B' : '#D3D6DF'
						}
					/>
				);
			case 'fifth':
				return (
					<FifthAppIcon
						color={
							app && app === 'fifth-app' ? '#555A6B' : '#D3D6DF'
						}
					/>
				);
		}
	};
	return (
		<div className='sticky top-28'>
			{sideBarData.map(data =>
				data.disabled ? (
					<div
						className='flex items-center px-2 justify-center py-5'
						key={data.id}
					>
						{iconSwitch(data.icon)}
					</div>
				) : (
					<Link
						to={data.link}
						key={data.id}
						className='flex items-center px-2 justify-center py-5'
					>
						{iconSwitch(data.icon)}
					</Link>
				),
			)}
		</div>
	);
};

export default SideBar;
