import { FC } from 'react';

const FirstAppIcon: FC<{ width?: number; height?: number; color?: string }> = ({
	width = 50,
	height = 50,
	color = '#555A6B',
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 50 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M1.45923 17.8396C2.00783 17.8396 2.45483 17.3926 2.45483 16.844V2.9665C2.45483 2.4179 2.90184 1.97089 3.45044 1.97089H17.328C17.8766 1.97089 18.3236 1.52389 18.3236 0.975287C18.3236 0.426688 17.8562 0 17.3076 0H3.43012C1.78432 0 0.463623 1.34102 0.463623 2.9665V16.844C0.463623 17.3926 0.91063 17.8396 1.45923 17.8396Z'
				fill={color}
			/>
			<path
				d='M47.0131 0H33.1356C32.587 0 32.14 0.447007 32.14 0.995606C32.14 1.5442 32.587 1.99121 33.1356 1.99121H47.0131C47.5617 1.99121 48.0087 2.43822 48.0087 2.98682V16.8643C48.0087 17.4129 48.4558 17.8599 49.0044 17.8599C49.5529 17.8599 50 17.4129 50 16.8643V2.9665C50 1.3207 48.6793 0 47.0335 0'
				fill={color}
			/>
			<path
				d='M17.3076 47.5452H3.43005C2.88145 47.5452 2.43445 47.0982 2.43445 46.5496V32.6721C2.43445 32.1235 1.98744 31.6765 1.43884 31.6765C0.890244 31.6765 0.443237 32.1235 0.443237 32.6721V46.5496C0.443237 48.1954 1.76394 49.5161 3.40974 49.5161H17.2873C17.8359 49.5161 18.2829 49.0691 18.2829 48.5205C18.2829 47.9719 17.8359 47.5249 17.2873 47.5249'
				fill={color}
			/>
			<path
				d='M49.0044 31.6968C48.4558 31.6968 48.0087 32.1438 48.0087 32.6924V46.57C48.0087 47.1186 47.5617 47.5656 47.0131 47.5656H33.1356C32.587 47.5656 32.14 48.0126 32.14 48.5612C32.14 49.1098 32.587 49.5568 33.1356 49.5568H47.0131C48.6589 49.5568 49.9796 48.2361 49.9796 46.5903V32.7128C49.9796 32.1642 49.5326 31.7172 48.984 31.7172'
				fill={color}
			/>
			<path
				d='M40.2065 11.5003H10.257C9.70843 11.5003 9.28174 11.0533 9.28174 10.525C9.28174 9.99669 9.72874 9.54968 10.257 9.54968H40.2065C40.7551 9.54968 41.1818 9.99669 41.1818 10.525C41.1818 11.0533 40.7347 11.5003 40.2065 11.5003Z'
				fill={color}
			/>
			<path
				d='M39.9016 14.6699H10.5414C9.83027 14.6699 9.26135 15.2389 9.26135 15.95V18.5508C9.26135 19.2619 9.83027 19.8308 10.5414 19.8308H39.9016C40.6128 19.8308 41.1817 19.2619 41.1817 18.5508V15.95C41.1817 15.2389 40.6128 14.6699 39.9016 14.6699Z'
				fill={color}
			/>
			<path
				d='M40.2065 25.0527H10.257C9.70843 25.0527 9.28174 24.6057 9.28174 24.0774C9.28174 23.5491 9.72874 23.1021 10.257 23.1021H40.2065C40.7551 23.1021 41.1818 23.5491 41.1818 24.0774C41.1818 24.6057 40.7347 25.0527 40.2065 25.0527Z'
				fill={color}
			/>
			<path
				d='M40.2065 30.5184H10.257C9.70843 30.5184 9.28174 30.0713 9.28174 29.5431C9.28174 29.0148 9.72874 28.5678 10.257 28.5678H40.2065C40.7551 28.5678 41.1818 29.0148 41.1818 29.5431C41.1818 30.0713 40.7347 30.5184 40.2065 30.5184Z'
				fill={color}
			/>
			<path
				d='M39.2108 33.5458H11.2322C10.1554 33.5458 9.26135 34.4195 9.26135 35.5167V38.0362C9.26135 39.1131 10.135 40.0071 11.2322 40.0071H39.2108C40.2877 40.0071 41.1817 39.1334 41.1817 38.0362V35.5167C41.1817 34.4398 40.308 33.5458 39.2108 33.5458Z'
				fill={color}
			/>
		</svg>
	);
};

export default FirstAppIcon;
