import { FC, ReactNode } from 'react';

const DashboardHeader: FC<{ icon: ReactNode; title: string }> = ({
	icon,
	title,
}) => {
	return (
		<div className='flex flex-row justify-start items-center space-x-4 w-full'>
			<div>{icon}</div>
			<h3 className='text-base font-medium capitalize text-gray-800'>
				{title}
			</h3>
		</div>
	);
};

export default DashboardHeader;
