const capitalize = (text: string) => {
	return text
		.split(' ')
		.map(word => word.charAt(0) + word.slice(1))
		.join(' ');
};

const toBase64 = (file: File): Promise<any> =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => reader.result && resolve(reader.result);
		reader.onerror = error => reject(error);
	});

export { capitalize, toBase64 };
