import { FC, useContext, useEffect, useState } from 'react';
import SearchIcon from '../../../assets/icons/searchIcon';
import { QuestionAnswerContext } from '../../../context/questionAnswerContext';
import Spinner from '../../loaders/spinner';

const Search: FC<{
	handleSearch: (text: string) => void;
	loadingContext?: boolean;
}> = ({ handleSearch, loadingContext }) => {
	const [searchText, setSearchText] = useState('');
	const { context } = useContext(QuestionAnswerContext);

	const handleKeyPress = (e: any) => {
		if (e.keyCode === 13) {
			handleSearch(searchText);
		}
	};

	useEffect(() => {
		context === '' && setSearchText('');
	}, [context]);

	return (
		<div className='rounded-md border border-gray-300 p-3 flex gap-x-1 bg-white'>
			<button onClick={() => handleSearch(searchText)}>
				<SearchIcon />
			</button>
			{loadingContext ? (
				<Spinner classes='w-7 h-7 !text-primary-blue !m-0' />
			) : (
				<input
					type='text'
					name='searchBar'
					id='searchBar'
					placeholder='What is company name?'
					className='border-none bg-white w-full bg-transparent focus:outline-none focus:ring-0'
					onChange={e => setSearchText(e.target.value)}
					onKeyDown={handleKeyPress}
					value={context === '' ? '' : searchText}
					disabled={context === ''}
				/>
			)}
		</div>
	);
};

export default Search;
