import axios from 'axios';
import { PDFParseResponse } from '../models/TextUtility';

const http = axios.create({
	baseURL: 'https://text-utility.ai-sandbox.4th-ir.io/api/v1',
});

class TextUtilityService {
	async parsePdf(file: File): Promise<PDFParseResponse> {
		const formData = new FormData();
		formData.append('pdf_file', file);

		return (
			await http.post('/pdf', formData, {
				headers: { enctype: 'multipart/form-data' },
			})
		).data;
	}
}

export default new TextUtilityService();
