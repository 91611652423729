import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { capitalize } from '../../utils/utilityFunctions';

const Seo: FC<{ title: string }> = ({ title }) => {
	return (
		<Helmet>
			<meta charSet='utf-8' />
			<title>{capitalize(title)} | PoC Demos </title>
			<link rel='canonical' href='http://mysite.com/example' />
		</Helmet>
	);
};

export default Seo;
