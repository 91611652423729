import { FC } from 'react';

const ApproveIcon: FC<{ width?: number; height?: number; color?: string }> = ({
	width = 22,
	height = 22,
	color = '#29A252',
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 22 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476612 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3165 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 9.55546 21.7155 8.12506 21.1627 6.79048C20.6099 5.4559 19.7996 4.24327 18.7782 3.22182C17.7567 2.20038 16.5441 1.39013 15.2095 0.837325C13.8749 0.284523 12.4445 0 11 0ZM11 19.8C9.25953 19.8 7.55814 19.2839 6.11099 18.3169C4.66383 17.35 3.53592 15.9756 2.86986 14.3676C2.20381 12.7596 2.02954 10.9902 2.36909 9.2832C2.70864 7.57617 3.54676 6.00816 4.77746 4.77746C6.00817 3.54676 7.57618 2.70864 9.28321 2.36909C10.9902 2.02954 12.7596 2.20381 14.3676 2.86986C15.9756 3.53591 17.35 4.66383 18.3169 6.11098C19.2839 7.55813 19.8 9.25952 19.8 11C19.8 13.3339 18.8729 15.5722 17.2225 17.2225C15.5722 18.8729 13.3339 19.8 11 19.8Z'
				fill={color}
			/>
			<path
				d='M13.97 7.02899L9.81201 12.529L8.01901 10.208C7.83959 9.97752 7.57596 9.82776 7.28612 9.79166C6.99629 9.75556 6.70398 9.83607 6.47351 10.0155C6.24303 10.1949 6.09327 10.4585 6.05717 10.7484C6.02107 11.0382 6.10159 11.3305 6.28101 11.561L8.95401 14.982C9.0576 15.1131 9.18969 15.2189 9.34026 15.2913C9.49083 15.3637 9.65592 15.4009 9.82301 15.4C9.991 15.3996 10.1567 15.3607 10.3073 15.2863C10.4579 15.212 10.5895 15.1041 10.692 14.971L15.719 8.37099C15.897 8.1376 15.9749 7.84307 15.9357 7.55221C15.8965 7.26134 15.7434 6.99795 15.51 6.81999C15.2766 6.64203 14.9821 6.56407 14.6912 6.60327C14.4004 6.64246 14.137 6.7956 13.959 7.02899H13.97Z'
				fill={color}
			/>
		</svg>
	);
};

export default ApproveIcon;
