import {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useState,
} from 'react';
import { VLAClassifyResponse } from '../models/VLAClassifier';

interface BulkContextType {
	files: File[];
	categories: VLAClassifyResponse[];
	categoriesDict: { [key: string]: number };
	isClassifying: boolean;
	selectedCategory: string;
	setFiles: Dispatch<SetStateAction<File[]>>;
	setCategories: Dispatch<SetStateAction<VLAClassifyResponse[]>>;
	setCategoriesDict: Dispatch<SetStateAction<{ [key: string]: number }>>;
	setIsClassifying: Dispatch<SetStateAction<boolean>>;
	setSelectedCategory: Dispatch<SetStateAction<string>>;
}

export const BulkContext = createContext<BulkContextType>({
	files: [],
	categories: [],
	categoriesDict: {},
	isClassifying: false,
	selectedCategory: '',
	setFiles: () => {
		console.log('');
	},
	setCategories: () => {
		console.log('');
	},
	setCategoriesDict: () => {
		console.log('');
	},
	setIsClassifying: () => {
		console.log('');
	},
	setSelectedCategory: () => {
		console.log('');
	},
});

export const BulkContextProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [files, setFiles] = useState<File[]>([]);
	const [categories, setCategories] = useState<VLAClassifyResponse[]>([]);
	const [categoriesDict, setCategoriesDict] = useState<{
		[key: string]: number;
	}>({});
	const [isClassifying, setIsClassifying] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState('');

	return (
		<BulkContext.Provider
			value={{
				files,
				categories,
				categoriesDict,
				isClassifying,
				selectedCategory,
				setFiles,
				setCategories,
				setCategoriesDict,
				setIsClassifying,
				setSelectedCategory,
			}}
		>
			{children}
		</BulkContext.Provider>
	);
};
