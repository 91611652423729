import { FC, useRef } from 'react';
import IconPlus from '../../../assets/icons/iconPlus';

const DocumentUpload: FC<{
	multiple?: boolean;
	accept?: string;
	handleFiles: (files: File[]) => void;
}> = ({ multiple, accept = 'application/pdf', handleFiles }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	const handleFileUpload = (e: { target: HTMLInputElement }) => {
		e.target.files &&
			e.target.files.length > 0 &&
			handleFiles(Array.from(e.target.files));
	};

	const handleOver = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		e.target.classList.add('drag-enter');
	};

	const handleLeave = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
		e.target.classList.remove('drag-enter');
	};

	const handleDrop = (e: any) => {
		e.preventDefault();
		e.stopPropagation();

		if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
			const files = Array.from(e.dataTransfer.files).filter((file: any) =>
				accept.split(',').includes(file.type),
			);
			handleFiles(files as File[]);
			e.target.classList.remove('drag-enter');
		}
	};

	const handleBrowse = () => {
		inputRef.current?.click();
	};

	return (
		<div
			className='bg-white w-full h-[120px] rounded-md border-dashed border-2 border-opacity-50 border-gray-400 flex flex-col items-center justify-center'
			onDragEnter={handleOver}
			onDragLeave={handleLeave}
			onDragOver={handleOver}
			onDrop={handleDrop}
		>
			<input
				type='file'
				ref={inputRef}
				accept={accept}
				className='hidden'
				multiple={multiple}
				onChange={handleFileUpload}
			/>
			<button className='cursor-pointer' onClick={handleBrowse}>
				<IconPlus />
			</button>
			<p className='text-base text-gray-600 pt-2.5'>Upload Document</p>
		</div>
	);
};

export default DocumentUpload;
