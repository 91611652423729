import { FC } from 'react';
import Layout from '../components/layout/layout';
import DashboardSwitch from '../components/shared/dashboard-switch/dashboardSwitch';
import SideBar from '../components/side-bar/sideBar';

const Dashboard: FC = () => {
	return (
		<Layout title='Virtual Legal Assistant'>
			<div className='w-full min-h-[calc(100vh_-_97px)] grid grid-cols-[120px_1fr]'>
				<div className='block w-full h-full relative inline-block py-10'>
					<SideBar />
				</div>
				<div className='w-full h-full bg-gray-100 py-10 pl-8 pr-20'>
					<div className='container mx-auto'>
						<DashboardSwitch />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Dashboard;
