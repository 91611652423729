import { FC } from 'react';

const FifthAppIcon: FC<{
	width?: number;
	height?: number;
	color?: string;
}> = ({ width = 50, height = 50, color = '#555A6B' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 50 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_409_695)'>
				<path
					d='M44.9979 0H5.00207C2.23233 0 0 2.23233 0 5.00207V44.9979C0 47.7677 2.23233 50 5.00207 50H44.9979C47.7677 50 50 47.7677 50 44.9979V5.00207C50 2.23233 47.7677 0 44.9979 0ZM47.995 44.9979C47.995 46.6515 46.6515 47.995 44.9979 47.995H5.00207C3.34849 47.995 2.00496 46.6515 2.00496 44.9979V5.00207C2.00496 3.34849 3.34849 2.00496 5.00207 2.00496H44.9979C46.6515 2.00496 47.995 3.34849 47.995 5.00207V44.9979Z'
					fill={color}
				/>
				<path
					d='M12.1744 17.838V32.6375L24.9897 40.0372L37.8049 32.6375V17.838L24.9897 10.4382L12.1744 17.838ZM34.2497 18.5201L25.0103 23.8528L15.771 18.5201L25.0103 13.1873L34.2497 18.5201ZM14.5721 20.587L23.8115 25.9198V36.5854L14.5721 31.2526V20.587ZM26.2092 36.5854V25.9198L35.4485 20.587V31.2526L26.2092 36.5854Z'
					fill={color}
				/>
				<path
					d='M24.9897 7.66846L30.2811 10.7276L31.48 8.6606L24.9897 4.91939L18.4994 8.6606L19.6982 10.7276L24.9897 7.66846Z'
					fill={color}
				/>
				<path
					d='M9.77677 16.4531L15.0682 13.394L13.89 11.327L7.39975 15.0682V22.5713H9.77677V16.4531Z'
					fill={color}
				/>
				<path
					d='M9.77677 27.9041H7.39975V35.4072L13.89 39.1484L15.0682 37.0814L9.77677 34.0223V27.9041Z'
					fill={color}
				/>
				<path
					d='M24.9897 42.8069L19.6982 39.7478L18.4994 41.8148L24.9897 45.556L31.48 41.8148L30.2811 39.7478L24.9897 42.8069Z'
					fill={color}
				/>
				<path
					d='M40.2026 34.0223L34.9111 37.0814L36.11 39.1484L42.6003 35.4072V27.9041H40.2026V34.0223Z'
					fill={color}
				/>
				<path
					d='M36.11 11.327L34.9111 13.394L40.2026 16.4531V22.5713H42.6003V15.0682L36.11 11.327Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_409_695'>
					<rect width={width} height={height} fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default FifthAppIcon;
