import { FC } from 'react';
import Logo from '../../assets/images/Logo.png';

const Nav: FC = () => {
	return (
		<div className='hidden lg:block w-full bg-primary-blue py-5 fixed px-10 top-0 z-50'>
			<img src={Logo} alt='no-img' />
		</div>
	);
};

export default Nav;
