import axios from 'axios';
import axiosRetry from 'axios-retry';
import {
	VLAClassifyDigitalRequest,
	VLAClassifyResponse,
} from '../models/VLAClassifier';

const http = axios.create({
	baseURL:
		'https://text-document-classification-vla-2.ai-sandbox.4th-ir.io/api/v1',
});

axiosRetry(http, {
	retries: 4,
	retryDelay: retryCount => {
		return retryCount * 2000;
	},
	retryCondition: err => {
		return err.response?.status === 400;
	},
});

class VLAClassifierService {
	async classify(file: File): Promise<VLAClassifyResponse> {
		const formData = new FormData();
		formData.append('pdf_file', file);
		return (
			await http.post('/classify-and-extract-meta-data/', formData, {
				headers: { enctype: 'multipart/form-data' },
			})
		).data;
	}

	async digitalClassify(
		body: VLAClassifyDigitalRequest,
	): Promise<VLAClassifyResponse> {
		return (
			await http.post(
				'/true-digital-classify-and-extract-meta-data/',
				body,
			)
		).data;
	}
}

export default new VLAClassifierService();
