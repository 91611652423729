import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { QuestionAnswerProvider } from '../../../context/questionAnswerContext';
import { BulkContextProvider } from '../../../context/vlaBulkContext';
import VlaBulkMain from '../../vla-bulk/vlaBulkMain';
import VlaQAMain from '../../vla-qa/vlaQAMain';

const DashboardSwitch: FC = () => {
	const { app } = useParams();

	switch (app) {
		case 'vla-qa':
			return (
				<QuestionAnswerProvider>
					<VlaQAMain />
				</QuestionAnswerProvider>
			);
		case 'vla-bulk':
			return (
				<BulkContextProvider>
					<VlaBulkMain />
				</BulkContextProvider>
			);
		default:
			return <div>QA-Screen</div>;
	}
};

export default DashboardSwitch;
