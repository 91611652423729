import { FC } from 'react';

const SecondAppIcon: FC<{
	width?: number;
	height?: number;
	color?: string;
}> = ({ width = 50, height = 50, color = '#555A6B' }) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 50 50'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<g clipPath='url(#clip0_409_770)'>
				<path
					d='M44.9979 0H5.00207C2.23233 0 0 2.23233 0 5.00207V44.9979C0 47.7677 2.23233 50 5.00207 50H44.9979C47.7677 50 50 47.7677 50 44.9979V5.00207C50 2.23233 47.7677 0 44.9979 0ZM47.995 44.9979C47.995 46.6515 46.6515 47.995 44.9979 47.995H5.00207C3.34849 47.995 2.00496 46.6515 2.00496 44.9979V5.00207C2.00496 3.34849 3.34849 2.00496 5.00207 2.00496H44.9979C46.6515 2.00496 47.995 3.34849 47.995 5.00207V44.9979Z'
					fill={color}
				/>
				<path
					d='M8.16449 33.4229H24.8243C26.2091 33.4229 27.3253 32.3067 27.3253 30.9219V13.3113H21.4964C20.1116 13.3113 18.9954 12.1951 18.9954 10.8103V4.9814H8.16449C6.77962 4.9814 5.66345 6.09756 5.66345 7.48243V30.9219C5.66345 32.3067 6.77962 33.4229 8.16449 33.4229ZM11.4923 16.7011H21.4964C21.9512 16.7011 22.3232 17.0732 22.3232 17.5279C22.3232 17.9826 21.9512 18.3547 21.4964 18.3547H11.4923C11.0376 18.3547 10.6655 17.9826 10.6655 17.5279C10.6655 17.0732 11.0376 16.7011 11.4923 16.7011ZM11.4923 20.0289H21.4964C21.9512 20.0289 22.3232 20.401 22.3232 20.8557C22.3232 21.3105 21.9512 21.6825 21.4964 21.6825H11.4923C11.0376 21.6825 10.6655 21.3105 10.6655 20.8557C10.6655 20.401 11.0376 20.0289 11.4923 20.0289ZM11.4923 23.3568H21.4964C21.9512 23.3568 22.3232 23.7288 22.3232 24.1835C22.3232 24.6383 21.9512 25.0103 21.4964 25.0103H11.4923C11.0376 25.0103 10.6655 24.6383 10.6655 24.1835C10.6655 23.7288 11.0376 23.3568 11.4923 23.3568ZM11.4923 26.6846H18.1686C18.6234 26.6846 18.9954 27.0566 18.9954 27.5114C18.9954 27.9661 18.6234 28.3382 18.1686 28.3382H11.4923C11.0376 28.3382 10.6655 27.9661 10.6655 27.5114C10.6655 27.0566 11.0376 26.6846 11.4923 26.6846Z'
					fill={color}
				/>
				<path
					d='M21.4965 11.637H26.8499L20.6697 5.4568V10.8103C20.6697 11.265 21.0417 11.637 21.4965 11.637Z'
					fill={color}
				/>
				<path
					d='M25.155 45.0186H41.8147C43.1996 45.0186 44.3158 43.9024 44.3158 42.5176V24.907H38.4869C37.1021 24.907 35.9859 23.7908 35.9859 22.406V16.5771H25.155C23.7701 16.5771 22.6539 17.6933 22.6539 19.0781V42.5176C22.6539 43.9024 23.7701 45.0186 25.155 45.0186ZM28.4828 28.2968H38.4869C38.9417 28.2968 39.3137 28.6689 39.3137 29.1236C39.3137 29.5783 38.9417 29.9504 38.4869 29.9504H28.4828C28.0281 29.9504 27.656 29.5783 27.656 29.1236C27.656 28.6689 28.0281 28.2968 28.4828 28.2968ZM28.4828 31.6246H38.4869C38.9417 31.6246 39.3137 31.9967 39.3137 32.4514C39.3137 32.9062 38.9417 33.2782 38.4869 33.2782H28.4828C28.0281 33.2782 27.656 32.9062 27.656 32.4514C27.656 31.9967 28.0281 31.6246 28.4828 31.6246ZM28.4828 34.9525H38.4869C38.9417 34.9525 39.3137 35.3245 39.3137 35.7792C39.3137 36.234 38.9417 36.606 38.4869 36.606H28.4828C28.0281 36.606 27.656 36.234 27.656 35.7792C27.656 35.3245 28.0281 34.9525 28.4828 34.9525ZM28.4828 38.2803H35.1591C35.6138 38.2803 35.9859 38.6523 35.9859 39.1071C35.9859 39.5618 35.6138 39.9339 35.1591 39.9339H28.4828C28.0281 39.9339 27.656 39.5618 27.656 39.1071C27.656 38.6523 28.0281 38.2803 28.4828 38.2803Z'
					fill={color}
				/>
				<path
					d='M38.4869 23.2327H43.8404L37.6602 17.0525V22.406C37.6602 22.8607 38.0322 23.2327 38.4869 23.2327Z'
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id='clip0_409_770'>
					<rect width={width} height={height} fill='white' />
				</clipPath>
			</defs>
		</svg>
	);
};

export default SecondAppIcon;
