import { FC } from 'react';

const SearchIcon: FC<{ width?: number; height?: number; color?: string }> = ({
	width = 34,
	height = 34,
	color = '#D3D6DF',
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 34 34'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M29.3392 27.3275L24.5225 22.525C26.0766 20.5451 26.9198 18.1003 26.9167 15.5833C26.9167 13.3418 26.252 11.1506 25.0067 9.28688C23.7613 7.42312 21.9913 5.9705 19.9204 5.1127C17.8495 4.25491 15.5708 4.03047 13.3723 4.46777C11.1739 4.90507 9.15446 5.98447 7.56946 7.56946C5.98447 9.15446 4.90507 11.1739 4.46777 13.3723C4.03047 15.5708 4.25491 17.8495 5.1127 19.9204C5.9705 21.9913 7.42312 23.7613 9.28688 25.0067C11.1506 26.252 13.3418 26.9167 15.5833 26.9167C18.1003 26.9198 20.5451 26.0766 22.525 24.5225L27.3275 29.3392C27.4592 29.472 27.6159 29.5773 27.7885 29.6493C27.9612 29.7212 28.1463 29.7582 28.3333 29.7582C28.5204 29.7582 28.7055 29.7212 28.8782 29.6493C29.0508 29.5773 29.2075 29.472 29.3392 29.3392C29.472 29.2075 29.5773 29.0508 29.6493 28.8782C29.7212 28.7055 29.7582 28.5204 29.7582 28.3333C29.7582 28.1463 29.7212 27.9612 29.6493 27.7885C29.5773 27.6159 29.472 27.4592 29.3392 27.3275ZM7.08334 15.5833C7.08334 13.9022 7.58185 12.2588 8.51585 10.861C9.44984 9.46317 10.7774 8.37371 12.3305 7.73036C13.8837 7.08702 15.5928 6.91869 17.2416 7.24666C18.8904 7.57464 20.405 8.38419 21.5937 9.57293C22.7825 10.7617 23.592 12.2762 23.92 13.9251C24.248 15.5739 24.0797 17.283 23.4363 18.8361C22.793 20.3893 21.7035 21.7168 20.3057 22.6508C18.9079 23.5848 17.2645 24.0833 15.5833 24.0833C13.329 24.0833 11.167 23.1878 9.57293 21.5937C7.97887 19.9997 7.08334 17.8377 7.08334 15.5833Z'
				fill={color}
			/>
		</svg>
	);
};

export default SearchIcon;
