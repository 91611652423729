import { FC } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Dashboard from '../pages/dashboard';

const AppRouter: FC = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<Navigate to={'/vla-qa'} />} />
				<Route path='/:app' element={<Dashboard />} />
			</Routes>
		</BrowserRouter>
	);
};

export default AppRouter;
