import { FC, useContext, useEffect, useState } from 'react';
import SearchIcon from '../../assets/icons/searchIcon';
import { BulkContext } from '../../context/vlaBulkContext';

const DocumentTable: FC = () => {
	const { selectedCategory, categories } = useContext(BulkContext);
	const [tableData, setTableData] = useState(
		categories.filter(v => v.document_class === selectedCategory),
	);
	const [searchFilter, setSearchFilter] = useState('');

	useEffect(() => {
		setTableData(
			categories.filter(
				v =>
					v.document_class === selectedCategory &&
					v.document_name
						.toLowerCase()
						.includes(searchFilter.toLowerCase()),
			),
		);
	}, [selectedCategory, categories, searchFilter]);

	return (
		<div className='space-y-3 px-3'>
			<div className='flex flex-row-reverse justify-between items-end'>
				<div className='bg-white flex items-center px-4 rounded-md w-[30%]'>
					<SearchIcon width={23} height={23} />
					<input
						type='text'
						name='searchBar'
						id='searchBar'
						className='border-none focus:outline-none focus:ring-0 w-full'
						placeholder='Document Name'
						onChange={e => setSearchFilter(e.target.value)}
					/>
				</div>
				{selectedCategory !== '' && (
					<p className='text-base font-semibold text-gray-600'>
						{selectedCategory} (
						{
							categories.filter(
								v => v.document_class === selectedCategory,
							).length
						}
						)
					</p>
				)}
			</div>
			<table className='table-auto w-full border border-gray-400 border-t-transparent border-collapse'>
				<thead className='text-left bg-gray-200'>
					<tr>
						<th className='px-7 py-2'>Document Name:</th>
					</tr>
				</thead>
				<tbody>
					{tableData.map((v, index: number) => (
						<tr
							key={index}
							className='border border-gray-400 bg-white'
						>
							<td className='px-7 py-2'>
								{index + 1}. {v.document_name}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default DocumentTable;
