import { FC } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	ChartData,
	ChartOptions,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface DoughnutProps {
	data: ChartData<'doughnut'>;
}

const DoughnutGraph: FC<DoughnutProps> = ({ data }) => {
	ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

	const options: ChartOptions<'doughnut'> = {
		maintainAspectRatio: false,
		responsive: true,
		layout: {
			padding: 40,
		},
		cutout: '40%',
		plugins: {
			datalabels: {
				color: 'white',
				labels: {
					title: {
						font: {
							family: 'Poppins, sans-serif',
							size: 12,
							weight: 'bold',
						},
					},
				},
				formatter: (value: any, context: any) => {
					const dataPoints = context.chart.data.datasets[0].data;
					const totalSum = (total: number, datapoint: number) => {
						return total + datapoint;
					};
					const totalValue = dataPoints.reduce(totalSum, 0);
					const percentValue = ((value / totalValue) * 100).toFixed(
						1,
					);
					return `${percentValue}%`;
				},
			},
			legend: {
				position: 'right',
				labels: {
					boxWidth: 20,
					boxHeight: 20,
					padding: 15,
					font: {
						size: 14,
						family: 'Poppins, sans-serif',
					},
					color: '#414A4D',
				},
			},
		},
	};

	return <Doughnut data={data} options={options} />;
};

export default DoughnutGraph;
