import { FC } from 'react';
import ApproveIcon from '../../../assets/icons/approveIcon';
import DeclineIcon from '../../../assets/icons/declineIcon';

const DocumentUploaded: FC<{
	title: string;
	handleApprove: () => void;
	handleDecline: () => void;
	disabledActions?: boolean;
	hideActions?: string[];
}> = ({
	title,
	handleApprove,
	handleDecline,
	disabledActions,
	hideActions,
}) => {
	return (
		<div className='flex justify-between items-center border-b-2 px-2.5 pb-2 pt-16'>
			<p>{title}</p>
			<div className='flex items-center gap-x-3.5 '>
				{!hideActions?.includes('approve') && (
					<button onClick={handleApprove} disabled={disabledActions}>
						<ApproveIcon />
					</button>
				)}
				{!hideActions?.includes('decline') && (
					<button onClick={handleDecline} disabled={disabledActions}>
						<DeclineIcon />
					</button>
				)}
			</div>
		</div>
	);
};

export default DocumentUploaded;
