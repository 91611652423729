import { FC, useContext, useEffect, useState } from 'react';
import { QuestionAnswerContext } from '../../context/questionAnswerContext';
import RobertaQAService from '../../services/RobertaQAService';
import Spinner from '../loaders/spinner';
import Search from '../shared/search/Search';

const QuestionAndAnswer: FC = () => {
	const { context, loadingContext, loadingAnswer, setLoadingAnswer } =
		useContext(QuestionAnswerContext);
	const [answer, setAnswer] = useState('');
	const [answerDetails, setAnswerDetails] = useState('');

	const handleSearch = (text: string) => {
		if (text === '') return;
		setLoadingAnswer(true);
		RobertaQAService.question({ context, question: text })
			.then(res => {
				setAnswer(res.answer);
				setLoadingAnswer(false);
			})
			.catch(() => setLoadingAnswer(false));
	};

	useEffect(() => {
		context === '' && setAnswer('');
	}, [context]);

	useEffect(() => {
		if (answer === '') {
			setAnswerDetails('');
			return;
		}
		const startEnd = [
			context.indexOf(answer),
			context.indexOf(answer) + answer.length - 1,
		];
		const start = startEnd[0] - 100 > 0 ? startEnd[0] - 100 : 0;
		const end =
			startEnd[1] + 100 < context.length - 1
				? startEnd[1] + 100
				: context.length - 1;
		setAnswerDetails(
			`${start > 0 ? '...' : ''}${context.substring(start, end)}${
				end < context.length - 1 ? '...' : ''
			}`,
		);
	}, [answer]);

	return (
		<div className='p-8 bg-[#F7F8FA] rounded-md drop-shadow space-y-10'>
			<div className='space-y-1.5'>
				<p className='text-base text-gray-400'>Question:</p>
				<Search
					handleSearch={handleSearch}
					loadingContext={loadingContext}
				/>
			</div>
			<div className='space-y-1.5'>
				<p className='text-base text-gray-400'>Answer:</p>
				<div className='bg-white rounded-md border border-gray-300 p-7 min-h-[250px]'>
					{loadingAnswer ? (
						<Spinner classes='w-7 h-7 !text-primary-blue !m-0' />
					) : (
						<div className='space-y-12'>
							<p className='text-base text-gray-800'>{answer}</p>
							{answerDetails !== '' && (
								<p className='text-base text-gray-400 pt-7 border-t-2 border-gray-300'>
									{answerDetails.substring(
										0,
										answerDetails.indexOf(answer),
									)}
									<b className='text-black'>{answer}</b>
									{answerDetails.slice(
										answerDetails.indexOf(answer) +
											answer.length,
										answerDetails.length,
									)}
								</p>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default QuestionAndAnswer;
