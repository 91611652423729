import { FC, useContext } from 'react';
import { QuestionAnswerContext } from '../../context/questionAnswerContext';
import Spinner from '../loaders/spinner';

const DocumentInfo: FC<{ label: string; value: string }> = ({
	label,
	value,
}) => {
	const { loadingClasses } = useContext(QuestionAnswerContext);

	return (
		<div className='bg-white rounded-md border-solid border border-gray-300 p-4 drop-shadow space-y-1'>
			<p className='text-basic text-gray-400'>{label}:</p>
			{loadingClasses ? (
				<Spinner classes='w-7 h-7 !text-primary-blue !m-0' />
			) : (
				<p className='text-basic text-gray-800 capitalize'>{value}</p>
			)}
		</div>
	);
};

export default DocumentInfo;
