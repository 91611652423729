import { FC } from 'react';

const DeclineIcon: FC<{ width?: number; height?: number; color?: string }> = ({
	width = 22,
	height = 22,
	color = '#ACB0BF',
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox='0 0 22 22'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M11 0C8.82441 0 6.69767 0.645139 4.88873 1.85383C3.07979 3.06253 1.66989 4.78049 0.83733 6.79048C0.00476612 8.80047 -0.213071 11.0122 0.211367 13.146C0.635804 15.2798 1.68345 17.2398 3.22183 18.7782C4.76021 20.3166 6.72022 21.3642 8.85401 21.7886C10.9878 22.2131 13.1995 21.9952 15.2095 21.1627C17.2195 20.3301 18.9375 18.9202 20.1462 17.1113C21.3549 15.3023 22 13.1756 22 11C22 9.55546 21.7155 8.12506 21.1627 6.79048C20.6099 5.4559 19.7996 4.24327 18.7782 3.22183C17.7567 2.20038 16.5441 1.39013 15.2095 0.837325C13.8749 0.284523 12.4445 0 11 0ZM11 19.8C9.25953 19.8 7.55814 19.2839 6.11099 18.3169C4.66383 17.35 3.53592 15.9756 2.86986 14.3676C2.20381 12.7596 2.02954 10.9902 2.36909 9.28321C2.70864 7.57617 3.54676 6.00816 4.77746 4.77746C6.00817 3.54676 7.57618 2.70864 9.28321 2.36909C10.9902 2.02954 12.7596 2.20381 14.3676 2.86986C15.9756 3.53591 17.35 4.66383 18.3169 6.11098C19.2839 7.55813 19.8 9.25952 19.8 11C19.8 13.3339 18.8729 15.5722 17.2225 17.2225C15.5722 18.8729 13.3339 19.8 11 19.8Z'
				fill={color}
			/>
			<path
				d='M13.981 8.019C13.8787 7.9159 13.7571 7.83406 13.623 7.77822C13.489 7.72237 13.3452 7.69362 13.2 7.69362C13.0548 7.69362 12.911 7.72237 12.777 7.77822C12.6429 7.83406 12.5213 7.9159 12.419 8.019L11 9.449L9.58101 8.019C9.37387 7.81186 9.09294 7.6955 8.80001 7.6955C8.50708 7.6955 8.22614 7.81186 8.01901 8.019C7.81187 8.22613 7.69551 8.50706 7.69551 8.8C7.69551 9.09293 7.81187 9.37386 8.01901 9.581L9.44901 11L8.01901 12.419C7.91591 12.5213 7.83407 12.6429 7.77823 12.777C7.72238 12.911 7.69363 13.0548 7.69363 13.2C7.69363 13.3452 7.72238 13.489 7.77823 13.623C7.83407 13.7571 7.91591 13.8787 8.01901 13.981C8.12127 14.0841 8.24293 14.1659 8.37697 14.2218C8.51102 14.2776 8.6548 14.3064 8.80001 14.3064C8.94522 14.3064 9.089 14.2776 9.22304 14.2218C9.35709 14.1659 9.47875 14.0841 9.58101 13.981L11 12.551L12.419 13.981C12.5213 14.0841 12.6429 14.1659 12.777 14.2218C12.911 14.2776 13.0548 14.3064 13.2 14.3064C13.3452 14.3064 13.489 14.2776 13.623 14.2218C13.7571 14.1659 13.8787 14.0841 13.981 13.981C14.0841 13.8787 14.1659 13.7571 14.2218 13.623C14.2776 13.489 14.3064 13.3452 14.3064 13.2C14.3064 13.0548 14.2776 12.911 14.2218 12.777C14.1659 12.6429 14.0841 12.5213 13.981 12.419L12.551 11L13.981 9.581C14.0841 9.47874 14.1659 9.35708 14.2218 9.22303C14.2776 9.08899 14.3064 8.94521 14.3064 8.8C14.3064 8.65478 14.2776 8.51101 14.2218 8.37696C14.1659 8.24292 14.0841 8.12126 13.981 8.019Z'
				fill={color}
			/>
		</svg>
	);
};

export default DeclineIcon;
