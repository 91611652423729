import { FC, useContext } from 'react';
import { CSVLink } from 'react-csv';
import SecondAppIcon from '../../assets/icons/secondAppIcon';
import { BulkContext } from '../../context/vlaBulkContext';
import { DocumentDetailsUI } from '../../models/MainQATypes';
import VLAClassifierService from '../../services/VLAClassifierService';
import { preloadDocuments } from '../../utils/data/staticData';
import Spinner from '../loaders/spinner';
import DashboardHeader from '../shared/dashboard-header/dashboardHeader';
import DocumentUpload from '../shared/document-upload/documentUpload';
import DocumentUploaded from '../shared/document-upload/documentUploaded';
import DoughnutGraph from '../shared/graphs/doughnutGraph';
import DocumentTable from './documentTable';

const VlaBulkMain: FC = () => {
	const {
		files,
		categoriesDict,
		categories,
		isClassifying,
		selectedCategory,
		setFiles,
		setCategories,
		setCategoriesDict,
		setIsClassifying,
		setSelectedCategory,
	} = useContext(BulkContext);

	const classifyFiles = async (fileList: File[], blocking = false) => {
		setIsClassifying(true);
		let finished = 0;

		for (const file of fileList) {
			const body = preloadDocuments.find(
				v => v.document_name === file.name,
			);
			if (body) {
				if (blocking) {
					try {
						const res = await VLAClassifierService.digitalClassify(
							body,
						);

						finished += 1;
						setCategories((oldVal: any) => {
							return [...oldVal, res];
						});
						setCategoriesDict(oldVal => {
							const newVal = oldVal[res.document_class]
								? oldVal[res.document_class] + 1
								: 1;
							return {
								...oldVal,
								[res.document_class]: newVal,
							};
						});
						if (selectedCategory === '') {
							setSelectedCategory(res.document_class);
						}
						if (fileList.length === finished) {
							setIsClassifying(false);
						}
					} catch (err) {
						console.log(err);
						finished += 1;
						if (fileList.length === finished) {
							setIsClassifying(false);
						}
					}
				} else {
					VLAClassifierService.digitalClassify(body)
						.then(res => {
							finished += 1;
							setCategories((oldVal: any) => {
								return [...oldVal, res];
							});
							setCategoriesDict(oldVal => {
								const newVal = oldVal[res.document_class]
									? oldVal[res.document_class] + 1
									: 1;
								return {
									...oldVal,
									[res.document_class]: newVal,
								};
							});
							if (selectedCategory === '') {
								setSelectedCategory(res.document_class);
							}
							if (fileList.length === finished) {
								setIsClassifying(false);
							}
						})
						.catch(() => {
							finished += 1;
							if (fileList.length === finished) {
								setIsClassifying(false);
							}
						});
				}
			} else {
				VLAClassifierService.classify(file)
					.then(res => {
						finished += 1;
						setCategories((oldVal: any) => {
							return [...oldVal, res];
						});
						setCategoriesDict(oldVal => {
							const newVal = oldVal[res.document_class]
								? oldVal[res.document_class] + 1
								: 1;
							return {
								...oldVal,
								[res.document_class]: newVal,
							};
						});
						if (selectedCategory === '') {
							setSelectedCategory(res.document_class);
						}
						if (fileList.length === finished) {
							setIsClassifying(false);
						}
					})
					.catch(() => {
						finished += 1;
						if (fileList.length === finished) {
							setIsClassifying(false);
						}
					});
			}
		}
	};

	const handleFiles = async (fileList: File[]) => {
		setFiles(oldVal => {
			return [...oldVal, ...fileList];
		});
		await classifyFiles(fileList);
	};

	const handleApprove = () => {
		setCategories([]);
		setCategoriesDict({});
		setSelectedCategory('');
		classifyFiles(files);
	};

	const handleDecline = () => {
		setFiles([]);
		setCategories([]);
		setCategoriesDict({});
		setSelectedCategory('');
	};

	const csvHeaders = [
		{ label: 'File Name', key: 'name' },
		{ label: 'Class', key: 'class' },
		{ label: 'Site ID', key: 'site_id' },
		{ label: 'Contract Start Date', key: 'contract_start_date' },
		{ label: 'Contract Expiration Date', key: 'contract_expiration_date' },
		{ label: 'Involved Parties', key: 'involved_parties' },
	];

	return (
		<div>
			<DashboardHeader
				icon={<SecondAppIcon width={27} height={27} />}
				title='Virtual Legal Assistant'
			/>
			<div className='grid grid-cols-[2fr_3fr] py-7 px-3 gap-x-20'>
				<div>
					{isClassifying ? (
						<Spinner classes='w-8 h-8 !text-primary-blue !mx-auto mt-10' />
					) : (
						<DocumentUpload
							multiple={true}
							accept='application/pdf,image/png,image/jpeg,image/jpg'
							handleFiles={handleFiles}
						/>
					)}

					{categories.length > 0 && (
						<>
							<DocumentUploaded
								title={`${categories.length} ${
									files.length === 1 ? 'File' : 'Files'
								} Uploaded`}
								handleApprove={handleApprove}
								handleDecline={handleDecline}
								disabledActions={isClassifying}
								hideActions={['approve']}
							/>
							{
								<CSVLink
									data={categories.map(v => {
										const detail: DocumentDetailsUI = {
											name: v.document_name,
											class: v.document_class,
											site_id: v.document_meta.site_id
												? v.document_meta.site_id
												: '-',
											contract_start_date: v.document_meta
												.start_date
												? v.document_meta.start_date
												: '-',
											contract_expiration_date: v
												.document_meta.end_date
												? v.document_meta.end_date
												: '-',
											involved_parties: v.document_meta
												.related_parties
												? v.document_meta.related_parties.join(
														', ',
												  )
												: '-',
										};
										return detail;
									})}
									headers={csvHeaders}
									filename={'report.csv'}
								>
									Export to CSV
								</CSVLink>
							}
						</>
					)}
				</div>
				<div className='bg-white min-h-[300px] rounded-md drop-shadow'>
					<DoughnutGraph
						data={{
							labels: Object.keys(categoriesDict),
							datasets: [
								{
									label: '# of Documents',
									data: Object.values(categoriesDict),
									backgroundColor: [
										'#C7E8F8',
										'#8FD1F2',
										'#4DB0DF',
										'#2092C7',
									],
									borderColor: [
										'#C7E8F8',
										'#8FD1F2',
										'#4DB0DF',
										'#2092C7',
									],
								},
							],
						}}
					/>
				</div>
				<div className='space-y-3'>
					<p className='text-base font-semibold '>Select Category:</p>
					<select
						className='w-full bg-gray-200 rounded-md focus:outline-none focus:ring-0 border-gray-800 focus:border-gray-800'
						value={selectedCategory}
						onChange={e => setSelectedCategory(e.target.value)}
					>
						<option value='' disabled={true}>
							--Please select category--
						</option>
						{Object.keys(categoriesDict).map(v => (
							<option key={v} value={v}>
								{v}
							</option>
						))}
					</select>
				</div>
			</div>
			<DocumentTable />
		</div>
	);
};

export default VlaBulkMain;
