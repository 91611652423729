import { FC, useContext } from 'react';
import FirstAppIcon from '../../assets/icons/firstAppIcon';
import { QuestionAnswerContext } from '../../context/questionAnswerContext';
import TextUtilityService from '../../services/TextUtilityService';
import VLAClassifierService from '../../services/VLAClassifierService';
import DashboardHeader from '../shared/dashboard-header/dashboardHeader';
import DocumentUpload from '../shared/document-upload/documentUpload';
import DocumentUploaded from '../shared/document-upload/documentUploaded';
import DocumentInfo from './documentInfo';
import QuestionAndAnswer from './questionAndAnswer';
import { CSVLink } from 'react-csv';
import { preloadDocuments } from '../../utils/data/staticData';

const VlaQAMain: FC = () => {
	const {
		file,
		details,
		loadingClasses,
		loadingContext,
		setFile,
		setDetails,
		setContext,
		setLoadingClasses,
		setLoadingContext,
		setLoadingAnswer,
	} = useContext(QuestionAnswerContext);

	const handleFiles = (fileList: File[]) => {
		setFile(fileList[0]);
	};

	const handleApprove = () => {
		if (!file) return;
		setLoadingClasses(true);
		setLoadingContext(true);

		const body = preloadDocuments.find(d => d.document_name === file.name);
		console.log(body);
		if (body) {
			VLAClassifierService.digitalClassify(body)
				.then(res => {
					setContext(res.document_text);
					setLoadingContext(false);
					setDetails(oldVal => {
						return {
							...oldVal,
							name: res.document_name,
							class: res.document_class,
							site_id: res.document_meta.site_id
								? res.document_meta.site_id
								: '-',
							contract_start_date: res.document_meta.start_date
								? res.document_meta.start_date
								: '-',
							contract_expiration_date: res.document_meta.end_date
								? res.document_meta.end_date
								: '-',
							involved_parties: res.document_meta.related_parties
								? res.document_meta.related_parties.join(', ')
								: '-',
						};
					});
					setLoadingClasses(false);
				})
				.catch(() => {
					setLoadingContext(false);
					setLoadingClasses(false);
				});
		} else {
			TextUtilityService.parsePdf(file)
				.then(res => {
					setContext(res.content.join('\n'));
					setLoadingContext(false);
				})
				.catch(() => setLoadingContext(false));
			VLAClassifierService.classify(file)
				.then(res => {
					setDetails(oldVal => {
						return {
							...oldVal,
							name: res.document_name,
							class: res.document_class,
							site_id: res.document_meta.site_id
								? res.document_meta.site_id
								: '-',
							contract_start_date: res.document_meta.start_date
								? res.document_meta.start_date
								: '-',
							contract_expiration_date: res.document_meta.end_date
								? res.document_meta.end_date
								: '-',
							involved_parties: res.document_meta.related_parties
								? res.document_meta.related_parties.join(', ')
								: '-',
						};
					});
					setLoadingClasses(false);
				})
				.catch(() => {
					setLoadingClasses(false);
				});
		}
	};

	const handleDecline = () => {
		setFile(null);
		setDetails({
			name: '-',
			class: '-',
			site_id: '-',
			contract_start_date: '-',
			contract_expiration_date: '-',
			involved_parties: '-',
		});
		setContext('');
		setLoadingClasses(false);
		setLoadingContext(false);
		setLoadingAnswer(false);
	};

	const csvHeaders = [
		{ label: 'File Name', key: 'name' },
		{ label: 'Class', key: 'class' },
		{ label: 'Site ID', key: 'site_id' },
		{ label: 'Contract Start Date', key: 'contract_start_date' },
		{ label: 'Contract Expiration Date', key: 'contract_expiration_date' },
		{ label: 'Involved Parties', key: 'involved_parties' },
	];

	return (
		<div>
			<DashboardHeader
				icon={<FirstAppIcon width={27} height={27} />}
				title='Virtual Legal Assistant'
			/>
			<div className='grid grid-cols-[2fr_3fr] py-7 px-3 gap-x-20'>
				<div>
					{!file && <DocumentUpload handleFiles={handleFiles} />}
					{file && (
						<DocumentUploaded
							title={file.name}
							handleApprove={handleApprove}
							handleDecline={handleDecline}
							disabledActions={loadingClasses || loadingContext}
						/>
					)}
					{details.class !== '-' && (
						<CSVLink
							data={[details]}
							headers={csvHeaders}
							filename={'report.csv'}
						>
							Export to CSV
						</CSVLink>
					)}
					<div className='mt-10 flex flex-col gap-y-3.5'>
						<DocumentInfo label='Class' value={details.class} />
						<DocumentInfo label='Site ID' value={details.site_id} />
						<DocumentInfo
							label='Contract Start date'
							value={details.contract_start_date}
						/>
						<DocumentInfo
							label='Contract Expiration/Termination date'
							value={details.contract_expiration_date}
						/>
						<DocumentInfo
							label='Involved Parties'
							value={details.involved_parties}
						/>
					</div>
				</div>
				<div className='mt-12'>
					<QuestionAndAnswer />
				</div>
			</div>
		</div>
	);
};

export default VlaQAMain;
