import axios from 'axios';
import { QuestionRequest, QuestionResponse } from '../models/RobertaQA';

const http = axios.create({
	baseURL:
		'https://text-question-answer-xlm-roberta-squad2.ai-sandbox.4th-ir.io/',
});

class RobertaQAService {
	async question(body: QuestionRequest): Promise<QuestionResponse> {
		return (await http.post('/question', body)).data;
	}
}

export default new RobertaQAService();
