import {
	createContext,
	Dispatch,
	FC,
	ReactNode,
	SetStateAction,
	useState,
} from 'react';
import { DocumentDetailsUI } from '../models/MainQATypes';

interface QuestionAnswerContextType {
	file: File | null;
	details: DocumentDetailsUI;
	context: string;
	loadingClasses: boolean;
	loadingContext: boolean;
	loadingAnswer: boolean;
	setFile: Dispatch<SetStateAction<File | null>>;
	setDetails: Dispatch<SetStateAction<DocumentDetailsUI>>;
	setContext: Dispatch<SetStateAction<string>>;
	setLoadingClasses: Dispatch<SetStateAction<boolean>>;
	setLoadingContext: Dispatch<SetStateAction<boolean>>;
	setLoadingAnswer: Dispatch<SetStateAction<boolean>>;
}

export const QuestionAnswerContext = createContext<QuestionAnswerContextType>({
	file: null,
	details: {
		name: '-',
		class: '-',
		site_id: '-',
		contract_start_date: '-',
		contract_expiration_date: '-',
		involved_parties: '-',
	},
	context: '',
	loadingAnswer: false,
	loadingClasses: false,
	loadingContext: false,
	setFile: () => {
		console.log('');
	},
	setDetails: () => {
		console.log('');
	},
	setContext: () => {
		console.log('');
	},
	setLoadingAnswer: () => {
		console.log('');
	},
	setLoadingClasses: () => {
		console.log('');
	},
	setLoadingContext: () => {
		console.log('');
	},
});

export const QuestionAnswerProvider: FC<{ children: ReactNode }> = ({
	children,
}) => {
	const [file, setFile] = useState<File | null>(null);
	const [loadingClasses, setLoadingClasses] = useState(false);
	const [loadingContext, setLoadingContext] = useState(false);
	const [loadingAnswer, setLoadingAnswer] = useState(false);
	const [context, setContext] = useState<string>('');
	const [details, setDetails] = useState<DocumentDetailsUI>({
		name: '-',
		class: '-',
		site_id: '-',
		contract_start_date: '-',
		contract_expiration_date: '-',
		involved_parties: '-',
	});

	return (
		<QuestionAnswerContext.Provider
			value={{
				file,
				details,
				context,
				loadingAnswer,
				loadingClasses,
				loadingContext,
				setFile,
				setDetails,
				setContext,
				setLoadingAnswer,
				setLoadingClasses,
				setLoadingContext,
			}}
		>
			{children}
		</QuestionAnswerContext.Provider>
	);
};
